@layer base {
  .app-Brand {
  display: flex;
  align-items: center;
  margin: 0 var(--sk-space-16) 0 var(--sk-space-24);
}

.app-Brand--footer {
  margin-bottom: var(--sk-space-16);
}

@media (--medium-viewport) {
  .app-Brand--footer {
    margin-bottom: 0;
  }
}

@media (--large-viewport) {
  .app-Brand {
    margin: 0;
  }
}


/******************************
  JT LOGO
*******************************/

.app-JtLogo {
  width: auto;
  height: 1.5rem;
}

@media (--medium-viewport) {
  .app-JtLogo___footer {
    height: 2rem;
  }
}

@media (--large-viewport) {
  .app-JtLogo___header {
    height: 2rem;
  }
}


/******************************
  CC LOGO
*******************************/

.app-Brand__CcImage {
  width: var(--image-width);
  border-radius: var(--image-border-radius);
}

.app-Brand__CcImage:last-of-type {
  margin-right: var(--image-spacing);
}

.app-Brand__CcImage + .app-Brand__CcImage {
  margin-left: var(--image-spacing);
}

.app-Brand__CcTitleGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--titleGroup-gap);
  margin-right: var(--titleGroup-margin-right);
}

.app-Brand__CcTitle {
  color: currentcolor;
  font-family: var(--fontHeading);
  font-size: var(--title-size);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.7px;
  line-height: var(--title-size);

  /* the name of the Career Center is displayed on a specific maximum number of lines and truncated with ellipsis beyond that */
  /* stylelint-disable-next-line order/properties-order */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--title-line-clamp);
  line-clamp: var(--title-line-clamp);
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-Brand__CcSubtitle {
  width: auto;
  height: var(--subtitle-height);
}

.app-Brand__CcLogo {
  /* Style specific to this element */
  display: flex;
  align-items: center;

  /* 
    We define custom properties for the two CC logo formats: small and big.
    They will be used by children of this element to adapt their styles.
  */

  --small-image-width: 24px;
  --big-image-width: 48px;

  --small-image-border-radius: var(--sk-radius-2);
  --big-image-border-radius: var(--sk-radius-4);

  --small-image-spacing: var(--sk-space-4);
  --big-image-spacing: var(--sk-space-8);

  --small-titleGroup-gap: var(--sk-space-2);
  --big-titleGroup-gap: var(--sk-space-4);

  --small-titleGroup-margin-right: 0;
  --big-titleGroup-margin-right: var(--sk-space-8);

  --small-title-size: 14px;
  --big-title-size: 16px;

  --small-title-line-clamp: 1;
  --big-title-line-clamp: 2;

  --small-subtitle-height: 9px;
  --big-subtitle-height: 10px;

  /* Mobile style for header and footer: small */

  --image-width: var(--small-image-width);
  --image-border-radius: var(--small-image-border-radius);
  --image-spacing: var(--small-image-spacing);

  --titleGroup-gap: var(--small-titleGroup-gap);
  --titleGroup-margin-right: var(--small-titleGroup-margin-right);

  --title-size: var(--small-title-size);
  --title-line-clamp: var(--small-title-line-clamp);

  --subtitle-height: var(--small-subtitle-height);
}

/* Tablet style for footer: big */

@media (--medium-viewport) {
  .app-Brand__CcLogo___footer {
    --image-width: var(--big-image-width);
    --image-border-radius: var(--big-image-border-radius);
    --image-spacing: var(--big-image-spacing);

    --titleGroup-gap: var(--big-titleGroup-gap);
    --titleGroup-margin-right: var(--big-titleGroup-margin-right);

    --title-size: var(--big-title-size);
    --title-line-clamp: var(--big-title-line-clamp);

    --subtitle-height: var(--big-subtitle-height);
  }
}

/* Desktop style for header and footer: big */

@media (--large-viewport) {
  .app-Brand__CcLogo {
    --image-width: var(--big-image-width);
    --image-border-radius: var(--big-image-border-radius);
    --image-spacing: var(--big-image-spacing);

    --titleGroup-gap: var(--big-titleGroup-gap);
    --titleGroup-margin-right: var(--big-titleGroup-margin-right);

    --title-size: var(--big-title-size);
    --title-line-clamp: var(--big-title-line-clamp);

    --subtitle-height: var(--big-subtitle-height);
  }
}

}