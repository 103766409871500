@layer modules, ui, base;
@layer base {
  .Brand_app-Brand__c4Qbx {
  display: flex;
  align-items: center;
  margin: 0 var(--sk-space-16) 0 var(--sk-space-24);
}

.Brand_app-Brand--footer__W59hv {
  margin-bottom: var(--sk-space-16);
}

@media (min-width: 48rem) {
  .Brand_app-Brand--footer__W59hv {
    margin-bottom: 0;
  }
}

@media (min-width: 64rem) {
  .Brand_app-Brand__c4Qbx {
    margin: 0;
  }
}


/******************************
  JT LOGO
*******************************/

.Brand_app-JtLogo__lRH5S {
  width: auto;
  height: 1.5rem;
}

@media (min-width: 48rem) {
  .Brand_app-JtLogo___footer__7MXeC {
    height: 2rem;
  }
}

@media (min-width: 64rem) {
  .Brand_app-JtLogo___header__EE3FC {
    height: 2rem;
  }
}


/******************************
  CC LOGO
*******************************/

.Brand_app-Brand__CcImage__DidkI {
  width: var(--image-width);
  border-radius: var(--image-border-radius);
}

.Brand_app-Brand__CcImage__DidkI:last-of-type {
  margin-right: var(--image-spacing);
}

.Brand_app-Brand__CcImage__DidkI + .Brand_app-Brand__CcImage__DidkI {
  margin-left: var(--image-spacing);
}

.Brand_app-Brand__CcTitleGroup___rWb3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--titleGroup-gap);
  margin-right: var(--titleGroup-margin-right);
}

.Brand_app-Brand__CcTitle__zWSQT {
  color: currentcolor;
  font-family: var(--fontHeading);
  font-size: var(--title-size);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.7px;
  line-height: var(--title-size);

  /* the name of the Career Center is displayed on a specific maximum number of lines and truncated with ellipsis beyond that */
  /* stylelint-disable-next-line order/properties-order */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--title-line-clamp);
  line-clamp: var(--title-line-clamp);
  overflow: hidden;
  text-overflow: ellipsis;
}

.Brand_app-Brand__CcSubtitle__a3OOB {
  width: auto;
  height: var(--subtitle-height);
}

.Brand_app-Brand__CcLogo__SvY8S {
  /* Style specific to this element */
  display: flex;
  align-items: center;

  /* 
    We define custom properties for the two CC logo formats: small and big.
    They will be used by children of this element to adapt their styles.
  */

  --small-image-width: 24px;
  --big-image-width: 48px;

  --small-image-border-radius: var(--sk-radius-2);
  --big-image-border-radius: var(--sk-radius-4);

  --small-image-spacing: var(--sk-space-4);
  --big-image-spacing: var(--sk-space-8);

  --small-titleGroup-gap: var(--sk-space-2);
  --big-titleGroup-gap: var(--sk-space-4);

  --small-titleGroup-margin-right: 0;
  --big-titleGroup-margin-right: var(--sk-space-8);

  --small-title-size: 14px;
  --big-title-size: 16px;

  --small-title-line-clamp: 1;
  --big-title-line-clamp: 2;

  --small-subtitle-height: 9px;
  --big-subtitle-height: 10px;

  /* Mobile style for header and footer: small */

  --image-width: var(--small-image-width);
  --image-border-radius: var(--small-image-border-radius);
  --image-spacing: var(--small-image-spacing);

  --titleGroup-gap: var(--small-titleGroup-gap);
  --titleGroup-margin-right: var(--small-titleGroup-margin-right);

  --title-size: var(--small-title-size);
  --title-line-clamp: var(--small-title-line-clamp);

  --subtitle-height: var(--small-subtitle-height);
}

/* Tablet style for footer: big */

@media (min-width: 48rem) {
  .Brand_app-Brand__CcLogo___footer__HJoI6 {
    --image-width: var(--big-image-width);
    --image-border-radius: var(--big-image-border-radius);
    --image-spacing: var(--big-image-spacing);

    --titleGroup-gap: var(--big-titleGroup-gap);
    --titleGroup-margin-right: var(--big-titleGroup-margin-right);

    --title-size: var(--big-title-size);
    --title-line-clamp: var(--big-title-line-clamp);

    --subtitle-height: var(--big-subtitle-height);
  }
}

/* Desktop style for header and footer: big */

@media (min-width: 64rem) {
  .Brand_app-Brand__CcLogo__SvY8S {
    --image-width: var(--big-image-width);
    --image-border-radius: var(--big-image-border-radius);
    --image-spacing: var(--big-image-spacing);

    --titleGroup-gap: var(--big-titleGroup-gap);
    --titleGroup-margin-right: var(--big-titleGroup-margin-right);

    --title-size: var(--big-title-size);
    --title-line-clamp: var(--big-title-line-clamp);

    --subtitle-height: var(--big-subtitle-height);
  }
}

}
@layer base {
  .LandingMessage_main__j4QaT {
  box-sizing: border-box;
  height: 100vh;
  padding: var(--sk-space-16);
}

.LandingMessage_content__hJVR8 {
  display: grid;
  height: 100%;
}

.LandingMessage_wrapper__MUonX {
  display: grid;
}

.LandingMessage_status__h8LRz {
  justify-self: center;
  width: 100%;
  max-width: 80%;
  text-align: center;
}

.LandingMessage_image__cCv2M {
  max-width: 100%;
  height: auto;
}

.LandingMessage_title__P3quW {
  margin-top: var(--sk-space-40);
  margin-bottom: var(--sk-space-8);
  font-size: var(--sk-font-size-3);
  line-height: var(--sk-line-heights-2);
}

.LandingMessage_text__6LABs {
  font-size: var(--sk-font-size-1);
  line-height: var(--sk-line-heights-4);
}

.LandingMessage_button__nR_3I {
  align-self: flex-end;
}

@media (min-width: 48rem) {
  .LandingMessage_main__j4QaT {
    padding: var(--sk-space-40);
  }

  .LandingMessage_wrapper__MUonX {
    align-self: baseline;
  }

  .LandingMessage_status__h8LRz {
    max-width: 812px;
  }

  .LandingMessage_title__P3quW {
    font-size: var(--sk-font-size-6);
    line-height: var(--sk-line-heights-0);
  }

  .LandingMessage_text__6LABs {
    font-size: var(--sk-font-size-2);
    line-height: var(--sk-line-heights-2);
  }

  .LandingMessage_button__nR_3I {
    place-self: auto center;
    margin-top: var(--sk-space-40);
  }
}

}
