@layer base {
  .main {
  box-sizing: border-box;
  height: 100vh;
  padding: var(--sk-space-16);
}

.content {
  display: grid;
  height: 100%;
}

.wrapper {
  display: grid;
}

.status {
  justify-self: center;
  width: 100%;
  max-width: 80%;
  text-align: center;
}

.image {
  max-width: 100%;
  height: auto;
}

.title {
  margin-top: var(--sk-space-40);
  margin-bottom: var(--sk-space-8);
  font-size: var(--sk-font-size-3);
  line-height: var(--sk-line-heights-2);
}

.text {
  font-size: var(--sk-font-size-1);
  line-height: var(--sk-line-heights-4);
}

.button {
  align-self: flex-end;
}

@media (--medium-viewport) {
  .main {
    padding: var(--sk-space-40);
  }

  .wrapper {
    align-self: baseline;
  }

  .status {
    max-width: 812px;
  }

  .title {
    font-size: var(--sk-font-size-6);
    line-height: var(--sk-line-heights-0);
  }

  .text {
    font-size: var(--sk-font-size-2);
    line-height: var(--sk-line-heights-2);
  }

  .button {
    place-self: auto center;
    margin-top: var(--sk-space-40);
  }
}

}